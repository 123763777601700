import React from "react"
import LayoutOrig from "../../../components/layout_fr"
import Seo from "../../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline"
/*import CONFIG from '../../global-vars-trezo.js'*/
import {GatsbyImage} from "gatsby-plugin-image";
import trezoCollab from "../../../images/tree.svg"


const Collaboratif = ({data}) => (
    <LayoutOrig>

        <Seo
            titleTemplate={`La collaboration au coeur de votre trésorerie | Trezorino`}
            title="La collaboration au coeur de votre trésorerie"
            description="Développez la culture cash au sein de votre entreprise en collaborant avec toutes les équipes concernées."
            image={trezoCollab}
            lang="fr"
        />



        <div className="">


            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">Développez la culture cash au sein de votre entreprise.</h1>
                            {/* <p className="section">Oubliez la gestion et le partage de tableau Excel statique et regroupez vos acteurs clefs autour d’un ensemble de chiffres fiables.</p>*/}
                            <p className="section">Avec une source unique de vérité et des parties prenantes engagées, vous êtes sur la bonne voie pour développer la culture cash dans votre entreprise.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/* <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                        <Col className="home-box-image-collab order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">La gestion de trésorerie, un travail d’équipe</h2>
                            <p className="section-h2">Chaque collaborateur à son niveau peut participer à la planification financière.</p>
                            <p className="section-h2">Trezorino offre la possibilité de gérer les rôles de vos collaborateurs, de segmenter votre trésorerie par département, entité, service ou agence.</p>
                            <p className="section-h2">Grâce à la collaboration, le processus de prévisions est plus rapide et la gestion de trésorerie plus fiable.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-line" >
                {data.trois.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">Partagez les informations avec tout le monde</h2>
                            <p className="section-h2">A travers Trezorino, les utilisateurs peuvent ajouter des commentaires et déposer des documents afin de partager leurs études, leurs hypothèses et diverses autres informations utiles à la planification.</p>
                            <p className="section-h2">Les documents et les commentaires sont organisés autour des scénarios, des prévisions, des transactions. Cela est extrêmement utile pour trouver des réponses rapidement.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (

                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>

                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container >
                {data.allStrapiTitleMenuFooter.edges.map(title => (
                    <Row key={title.node.id}  className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">{title.node.Title}</h2>
                    </Row>
                ))}

                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4">
                    {data.allStrapiFooterMenus.edges.map(menu => (
                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <GatsbyImage key={ImageCard.id}  image={ImageCard?.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.Title}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/fr"+menu.node.LinkHref+"/"}  className="card-link-footer">En savoir plus<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                                {/* <Button variant="primary" className="collab-button-footer-card"  href="/fr"+menu.node.LinkHref >Essai gratuit</Button> */}
                            </Card.Footer>
                        </Card>
                    ))}
                </Row>

                <Row className="justify-content-md-center pb-5 pt-5">
                </Row>

            </Container>


        </div>
    </LayoutOrig>
)

export default Collaboratif



export const data = graphql`  
query collaboration_fr {
  first : allStrapiFeaturesCollaborations(filter: {id: {eq: "Features-collaborations_1"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  second : allStrapiFeaturesCollaborations(filter: {id: {eq: "Features-collaborations_2"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  trois : allStrapiFeaturesCollaborations(filter: {id: {eq: "Features-collaborations_3"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  all : allStrapiFeaturesCollaborations(
    filter: {id: {ne: "Features-collaborations_4"}}
    sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
